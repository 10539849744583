import { faBars, faPlus, faSearch } from "@fortawesome/pro-light-svg-icons"
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { humanNameAsString } from "fhir"
import { FormikHelpers, FormikValues } from "formik"
import { Divider } from "primereact/divider"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { useEffect, useId, useMemo, useReducer, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useSearchParams } from "react-router-dom"

import {
  Button,
  ConfirmDialog,
  DialogFormContainer,
  EmailCheckContext,
  EmailCheckProvider,
  GroupedList,
  InputField,
  LoadingView,
  PractitionerApi,
  ReferenceDropdownField,
  SearchWithFilters,
  SkeletonLoader,
  StackedListItem,
  useScreenContext,
  useSmartyAddressVerification,
} from "commons"
import { DataContainerForm } from "commons/form/DataContainerForm"
import { IDENTIFIER_CODE } from "data"
import { useDiagnosticLaboratories } from "organizations"

import {
  useActiveDeactivePractitioner,
  useCreatePractitioner,
  useInvitePractitioner,
  usePractitioner,
  usePractitionerLabSetup,
  usePractitioners,
  useUpdatePractitioner,
} from "../hooks"
import { FilterProps } from "../types"
import { PractitionerFilters } from "./PractitionerFilters"
import { PractitionerForm } from "./PractitionerForm"
import { PractitionerInvite } from "./PractitionerInvite"
import { practModelBuilder } from "./practModelBuilder"
import { APIPractitionerValidationSchema, initialValues, LabSetupValidationSchema, sanitize } from "./validations"

const PractitionerList = () => {
  const { searchText, gender, email, organization, role, status, search, filter, reset } = useReducerState()
  const [showInviteDialog, setShowInviteDialog] = useState(false)
  const [showActiveDeactiveDialog, setShowActiveDeactiveDialog] = useState(false)
  const [showLabSetupDialog, setShowLabSetupDialog] = useState(false)
  const [pract, setPract] = useState<PractitionerApi | undefined>(undefined)
  const [showForm, setShowForm] = useState(false)
  const [params, setParams] = useSearchParams()

  const { practitioner, isLoading: isLoadingPractitioner } = usePractitioner(pract?.practitioner.id)
  const { updatePractitionerStatus, isUpdating: isChangingStatus } = useActiveDeactivePractitioner()
  const practitionerLabsAlreadyConfigure = useMemo(
    () => pract?.practitioner?.identifier?.filter((i) => i.type?.coding?.[0].code === IDENTIFIER_CODE.AN),
    [pract],
  )
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()
  const { practitionerLabSetup, isPending } = usePractitionerLabSetup(pract?.practitioner.id ?? "", () =>
    setShowLabSetupDialog(false),
  )
  const { diagnosticLabs } = useDiagnosticLaboratories("")

  const { practitionerGroups, isLoading, isFetchingNextPage, count, total, hasNextPage, fetchNextPage } =
    usePractitioners(searchText, email, gender, organization, role, status)

  const { invitePractitioner, isInviting } = useInvitePractitioner(
    pract?.practitioner?.id as string,
    humanNameAsString(pract?.practitioner?.name?.[0]),
    () => {
      setShowInviteDialog(false)
    },
  )
  const closeForm = () => {
    params.delete("practId")
    setParams(params)
    setPract(undefined)
    setShowForm(false)
  }

  useEffect(() => {
    if (params.has("practId")) {
      setPract({
        practitioner: {
          id: params.get("practId") as string,
        },
      })
    }
    setShowForm(params.has("practId"))
  }, [params])

  const { createPractitioner, isAdding } = useCreatePractitioner(closeForm)
  const { updatePractitioner, isUpdating } = useUpdatePractitioner(closeForm)

  const edit = (practitioner: PractitionerApi) => {
    params.append("practId", practitioner.practitioner?.id ?? "")
    setParams(params)
    setPract(practitioner)
    setShowForm(true)
  }

  const { checkAddress, clearVerificationInfo } = useSmartyAddressVerification()

  const practitionerAction = (pract: PractitionerApi, isNew: boolean) =>
    isNew ? createPractitioner(pract) : updatePractitioner(pract)

  const onSubmit = async (practitioner: PractitionerApi, formikHelpers?: FormikHelpers<FormikValues> | undefined) => {
    const pract = sanitize(practitioner)
    const isNew = !practitioner.practitioner?.id

    if (pract?.practitioner?.address?.[0]) {
      await checkAddress(pract?.practitioner?.address?.[0], formikHelpers, () => {
        clearVerificationInfo()
        practitionerAction(pract, isNew)
      })
    } else {
      practitionerAction(pract, isNew)
    }
  }

  const handleActiveDeactivePractitioner = (pract: PractitionerApi) => {
    setPract(pract)
    setShowActiveDeactiveDialog(true)
  }
  const handlePractitionerInvite = (pract: PractitionerApi) => {
    setPract(pract)
    setShowInviteDialog(true)
  }
  const handlePractitionerLabSetup = (pract: PractitionerApi) => {
    setPract(pract)
    setShowLabSetupDialog(true)
  }
  const hideInviteDialog = () => {
    setShowInviteDialog(false)
    setPract(undefined)
  }
  const hideActiveDeactiveDialog = () => {
    setShowActiveDeactiveDialog(false)
    setPract(undefined)
  }
  const changeStatus = () => {
    updatePractitionerStatus(practitioner)
    setPract(undefined)
  }
  const hidePractitionerLabSetup = () => {
    setPract(undefined)
    setShowLabSetupDialog(false)
  }
  const overlayFilter = useRef<OverlayPanel>(null)
  const [overlaySidebarFilter, setOverlaySidebarFilter] = useState(false)
  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="list" />

  return (
    <div className="pt-6 bg-white h-full flex flex-col">
      {!showForm && (
        <div className="px-6 border-b shadow-sm">
          <div className={classNames(" flex", { "justify-between": isSmallScreen })}>
            {isSmallScreen ? (
              <FontAwesomeIcon
                icon={faBars}
                size="lg"
                className="cursor-pointer pt-0.5 hover:text-primary-hover mt-1"
                onClick={() => setSidebarCollapsed(false)}
              />
            ) : null}
            <div className={classNames("ml-2", { "flex flex-col items-end": isSmallScreen })}>
              <h6 className="font-medium text-lg">Practitioners</h6>
              <p className="text-sm text-gray-500">
                Showing {count} practitioners of {total} found
              </p>
            </div>
          </div>

          <div className="flex py-4 w-full justify-between">
            <div className="flex mr-2">
              <SearchWithFilters
                showOverlaypanel={overlayFilter}
                showSidebar={overlaySidebarFilter}
                setShowSidebar={setOverlaySidebarFilter}
                onTextFilter={search}
                isLoading={isLoading || isFetchingNextPage}
                filterNone={!email && !gender && !organization && !role && !status}
                formContent={
                  <PractitionerFilters
                    initialValues={{ gender, email, organization, role, status }}
                    onSearch={(filters) => {
                      filter(filters)
                      overlayFilter?.current?.hide()
                      setOverlaySidebarFilter(false)
                    }}
                    onClearFilters={() => {
                      reset()
                      overlayFilter?.current?.hide()
                      setOverlaySidebarFilter(false)
                    }}
                  />
                }
              />
            </div>

            <Button
              label={isSmallScreen ? "Add" : "Add practitioner"}
              icon={isSmallScreen ? faPlus : undefined}
              className="button-primary "
              onClick={() => setShowForm(true)}
            />
          </div>
        </div>
      )}

      {isLoading ? (
        loader()
      ) : (
        <EmailCheckProvider>
          <DataContainerForm
            hasData={count > 0}
            showForm={showForm}
            showAddButton={false}
            formTitle="Practitioner"
            iconDataNotFound={faUserPlus}
            formInitialValue={initialValues(practitioner)}
            validationSchema={APIPractitionerValidationSchema("practitioner")}
            onSubmit={onSubmit}
            onCancel={closeForm}
            form={
              isLoadingPractitioner ? <LoadingView /> : <PractitionerForm initialValues={initialValues(practitioner)} />
            }
            onButtonAddClick={() => setShowForm(true)}
            cancelButtonLabel="Close"
            customSaveButton={() => (
              <EmailCheckContext.Consumer>
                {({ isCheckingEmailInUse }) => (
                  <Button label="Save" disabled={isCheckingEmailInUse} loading={isAdding || isUpdating} type="submit" />
                )}
              </EmailCheckContext.Consumer>
            )}
          >
            <div className="flex flex-col overflow-auto grow">
              <InfiniteScroll
                hasMore={hasNextPage}
                loadMore={() => fetchNextPage()}
                useWindow={false}
                loader={loader()}
              >
                <GroupedList
                  className="grow"
                  groups={practitionerGroups}
                  renderDivider={(name) => (
                    <div className="bg-gray-50 px-6 py-1 border-t border-b first:border-t-0">
                      <p className="text-sm text-gray-500 font-medium capitalize">{name}</p>
                    </div>
                  )}
                  renderItem={(pract) => (
                    <>
                      <StackedListItem
                        itemPadding
                        modelData={practModelBuilder(
                          pract ?? {},
                          edit,
                          handleActiveDeactivePractitioner,
                          handlePractitionerInvite,
                          handlePractitionerLabSetup,
                        )}
                      />
                    </>
                  )}
                  renderEmptyState={() => (
                    <div className="flex flex-col items-center justify-center pt-10">
                      <FontAwesomeIcon icon={faSearch} size="2x" className="text-slate-500" />
                      <p className="text-slate-400 pt-3">No results found, please change filters and try again</p>
                    </div>
                  )}
                />
              </InfiniteScroll>

              {
                <ConfirmDialog
                  visible={showActiveDeactiveDialog || isChangingStatus}
                  onConfirm={changeStatus}
                  hideDialog={hideActiveDeactiveDialog}
                  isLoading={isChangingStatus}
                  confirmText={`Do you want to ${
                    pract?.practitioner?.active === false ? "activate" : "deactivate"
                  } this practitioner?`}
                />
              }
              {showInviteDialog && (
                <PractitionerInvite
                  showInviteDialog={showInviteDialog}
                  practitioner={pract?.practitioner ?? {}}
                  isInviting={isInviting}
                  hideInviteDialog={hideInviteDialog}
                  invitePractitioner={invitePractitioner}
                />
              )}
              {showLabSetupDialog && (
                <DialogFormContainer
                  title="Laboratory setup"
                  showForm={showLabSetupDialog || isPending}
                  onCancel={hidePractitionerLabSetup}
                  onSubmit={(data) => practitionerLabSetup(data ?? {})}
                  initialValue={{ value: "", organization: undefined }}
                  validationSchema={LabSetupValidationSchema}
                >
                  <div className="w-full pb-3">
                    {!!practitionerLabsAlreadyConfigure?.length && (
                      <section className="flex flex-col mb-3">
                        <h3 className="text-gray-700 font-medium mb-1">Fully configured labs:</h3>
                        <ul>
                          {practitionerLabsAlreadyConfigure.map((i, index) => (
                            <li className="text-sm text-gray-400" key={i.id ?? index}>
                              <span title="Assigner">{i.assigner?.display ?? "Unspecified laboratory"}</span> -{" "}
                              <span title="Account number">{i.value ?? "Unspecified account number"}</span>
                            </li>
                          ))}
                        </ul>
                        <Divider />
                      </section>
                    )}
                    <InputField field="value" label="Account number" />
                    <ReferenceDropdownField field="organization" label="Organization" options={diagnosticLabs} />
                  </div>
                </DialogFormContainer>
              )}
            </div>
          </DataContainerForm>
        </EmailCheckProvider>
      )}
    </div>
  )
}

const initialState = {
  searchText: "",
  email: "",
  gender: "",
  organization: undefined,
  role: "",
  status: "",
} as FilterProps

const reducer = (
  state: FilterProps,
  { type, payload }: { type: "reset" | "search" | "filter"; payload?: string | FilterProps },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "search":
      return { ...state, searchText: payload as string }
    case "filter":
      return { ...state, ...(payload as FilterProps) }
    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ searchText, email, gender, organization, role, status }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const search = (searchText: string) => {
    dispatch({ type: "search", payload: searchText })
  }

  const filter = (filters: FilterProps) => {
    dispatch({ type: "filter", payload: filters })
  }

  return { searchText, email, gender, organization, role, status, filter, search, reset }
}

export { PractitionerList }
