import { asReference, Organization, Reference } from "fhir"
import { useFormikContext } from "formik"
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox"
import { classNames } from "primereact/utils"
import { useCallback, useEffect, useState } from "react"

import {
  AddressField,
  AddressVerificationFeedback,
  EmailField,
  InputField,
  ReferenceDropdownField,
  useScreenContext,
  useSmartyAddressVerificationContext,
  ValueSetIds,
} from "commons"
import { organizationExtraTypes } from "data"
import { IdentifierContainer } from "identifier"
import { useValueSet } from "value-set"

const OrganizationForm = ({ organizationsRoots, isEditing }: Props) => {
  const {
    values: { type, partOf },
    setFieldValue,
  } = useFormikContext<Organization>()

  const { addressVerificationInfo, autoCompleteRecommendedAddress } = useSmartyAddressVerificationContext()

  const [autoShippingFree, setAutoShipping] = useState(false)

  const { isSmallScreen } = useScreenContext()
  const { codes } = useValueSet({ valueSetId: ValueSetIds.ORGANIZATION_IDENTIFIERS, enabled: true })

  useEffect(() => {
    partOf && setFieldValue("partOf", asReference(partOf))
  }, [])

  useEffect(() => {
    const isAutoShippingEnabled = type?.some((t) => t.coding?.[0]?.code === "support-auto-shipping-free")
    setAutoShipping(isAutoShippingEnabled ?? false)
  }, [])

  const setAutoShippingFree = useCallback(
    (e: CheckboxChangeEvent) => {
      const types = !e.checked
        ? type?.filter(
            ({ coding }) =>
              coding?.[0]?.code !== organizationExtraTypes["support-auto-shipping-free"].coding?.[0]?.code,
          ) ?? []
        : [...(type ?? []), organizationExtraTypes["support-auto-shipping-free"]]

      setAutoShipping(e.checked ?? false)

      setFieldValue("type", types)
    },
    [type, setFieldValue],
  )

  return (
    <div className="w-full flex justify-center">
      <div
        className={classNames("flex flex-col justify-between content-between", {
          "w-[80%] relative p-fluid grid lg:grid-cols-2 gap-4 lg:gap-5 mb-3 items-start": !isSmallScreen,
        })}
      >
        <div
          className={classNames("flex flex-col grow left-col p-3 gap-3 h-min top-0", {
            " justify-stretch": !isSmallScreen,
          })}
        >
          <fieldset className="grid gap-5 p-3 mb-5">
            <legend>Basic Information</legend>
            <InputField field="name" label="Name" />
            {(!isEditing || !!partOf) && (
              <ReferenceDropdownField
                label="Part of"
                field="partOf"
                options={isEditing && !!partOf ? [asReference(partOf)] : organizationsRoots}
                allowClear
                disabled={isEditing}
              />
            )}
            <div className="flex items-center text-sm gap-1 w-full mt-3">
              <Checkbox inputId="auto-shipping" onChange={setAutoShippingFree} checked={autoShippingFree} />
              <label htmlFor="auto-shipping" className="text-gray-500 text-sm cursor-pointer">
                {organizationExtraTypes["support-auto-shipping-free"].text}
              </label>
            </div>
          </fieldset>
          <div className="flex flex-col justify-between divide-y divide-gray-300">
            <div className="relative p-fluid grid gap-4">
              <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-5">
                <legend>Address</legend>
                <AddressField context="Organization" parentFieldName="address[0]" />
              </fieldset>
            </div>
            <AddressVerificationFeedback
              addressVerificationInfo={addressVerificationInfo}
              handleAutoCompleteRecommendedAddress={() => autoCompleteRecommendedAddress?.(setFieldValue, "address[0]")}
            />
          </div>
          <fieldset className="grid gap-5 p-3 mb-5">
            <legend>Telecom</legend>
            <EmailField field="telecom[0].value" label="Email" initialValue={""} />
            <InputField type="tel" mask="+1 (999) 999-9999" unmask={true} field="telecom[1].value" label="Phone" />
            <InputField type="tel" mask="+1 (999) 999-9999" unmask={true} field="telecom[2].value" label="Fax Number" />
          </fieldset>
        </div>
        <div className="flex flex-col grow right-col  p-3 gap-3 min-h-max">
          <fieldset className="grid gap-5 p-3 mb-5 border-2 rounded-lg">
            <IdentifierContainer field="identifier" label="Identifiers" types={codes} />
          </fieldset>
        </div>
      </div>
    </div>
  )
}
type Props = {
  organizationsRoots?: Reference[] | undefined
  isEditing: boolean
}

export { OrganizationForm }
