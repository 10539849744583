import { faArrowsRotateReverse } from "@fortawesome/pro-duotone-svg-icons"
import { faCreditCard } from "@fortawesome/pro-regular-svg-icons"
import { faBuilding, faCalendarCheck, faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Address, Extension } from "fhir"
import { AccountBETACreditCardArray } from "fhir/fhir-extended"
import { FormikHelpers } from "formik"
import { FC } from "react"

import { faPlus } from "@fortawesome/pro-light-svg-icons"
import {
  CreditCardForm,
  CreditCardFormData,
  CreditCardImage,
  creditCardValidationSchema,
  getInitialValues,
  useCreateCreditCard,
  useDeleteCreditCard,
  useUpdateCreditCard,
} from "account"
import {
  Button,
  ConfirmDialog,
  DataContainerSlideoverForm,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
  useScreenContext,
  useSmartyAddressVerification,
} from "commons"
import { useCreateOrganizationAccount, useOrganizationContext } from "organizations"
import { formatCreditCardNumber } from "utils"

const PaymentMethod: FC = () => {
  const { currentOrganization, currentOrganizationCCAccount, currentOrganizationId } = useOrganizationContext()
  const creditCards = currentOrganizationCCAccount?.creditCard ?? []
  const creditCardId = creditCards[0] && `${creditCards[0].type}|${creditCards[0].last4Digits}`

  const { showSlide, initialValue, isNew, deleteIndex, reset, add, edit, setDeleteIndex } = useCrudReducer({
    defaultEntity: getInitialValues(currentOrganization),
  })
  const { isSmallScreen } = useScreenContext()
  const { createCreditCard } = useCreateCreditCard(reset)
  const { updateCreditCard, isUpdating } = useUpdateCreditCard(reset)
  const { removeCreditCard, isDeleting } = useDeleteCreditCard(() => setDeleteIndex())
  const { createOrgAccount } = useCreateOrganizationAccount(currentOrganization, reset)

  const { checkAddress, clearVerificationInfo } = useSmartyAddressVerification()

  const creditCardAction = (creditCard: AccountBETACreditCardArray<Address, Extension>) =>
    isNew
      ? createCreditCard({
          creditCard,
          account: currentOrganizationCCAccount!,
          creditCardList: creditCards,
          organizationId: currentOrganizationId,
        })
      : updateCreditCard({
          creditCard,
          account: currentOrganizationCCAccount!,
        })

  const onSubmit = async (
    creditCard: AccountBETACreditCardArray<Address, Extension>,
    formikHelpers?: FormikHelpers<CreditCardFormData> | undefined,
  ) => {
    if (!currentOrganizationCCAccount) {
      createOrgAccount({ creditCard })
      return
    }

    if (creditCard?.billingAddress) {
      await checkAddress(creditCard.billingAddress!, formikHelpers, () => {
        clearVerificationInfo()
        creditCardAction(creditCard)
      })
    } else {
      creditCardAction(creditCard)
    }
  }

  return (
    <DataContainerSlideoverForm
      hasData={!!creditCards?.length}
      height="calc(100% - 8rem)"
      showSlide={showSlide}
      formTitle={"Credit Card"}
      formInitialValue={initialValue}
      validationSchema={creditCardValidationSchema}
      onSubmit={onSubmit}
      showAddButton={!isSmallScreen}
      onCancel={reset}
      form={<CreditCardForm isEditing={!isNew} shippingAddress={currentOrganization.address?.[0]} />}
      customAddButtonText={creditCards?.length ? "Replace credit card" : "Add new credit card"}
      customAddIcon={faArrowsRotateReverse}
      onButtonAddClick={add}
      iconDataNotFound={faCreditCard}
      subMessageDataNotFound="Get started by adding a new Credit Card"
    >
      <div>
        {isSmallScreen && (
          <div className="flex flex-col items-end mb-2">
            <Button label="Add" icon={faPlus} className="button-primary sm:text-sm w-20 h-8" onClick={add} />
          </div>
        )}
      </div>
      <div className="bg-white h-full overflow-auto">
        <StackedListContainer
          data={creditCards}
          itemModelBuilder={(item, index) =>
            modelBuilder(
              item,
              isUpdating,
              isExpired(item),
              () => edit(item),
              () => setDeleteIndex(index),
            )
          }
        />
      </div>
      <ConfirmDialog
        confirmText={`Are you sure you want to remove this credit card?`}
        actionName="Remove"
        visible={deleteIndex !== undefined || isDeleting}
        isLoading={isDeleting}
        onConfirm={() =>
          removeCreditCard({
            index: deleteIndex as number,
            accountId: currentOrganizationCCAccount?.id as string,
            creditCardList: creditCards,
            defaultCreditCardId: creditCardId,
            organizationId: currentOrganizationId,
          })
        }
        hideDialog={() => setDeleteIndex()}
      />
    </DataContainerSlideoverForm>
  )
}

const isExpired = (creditCard: AccountBETACreditCardArray<Address, Extension>) =>
  new Date(parseInt(creditCard?.expirationYear as string), parseInt(creditCard?.expirationMonth as string), 1) <
  new Date()

const modelBuilder = (
  creditCard: AccountBETACreditCardArray<Address, Extension>,
  isUpdating: boolean,
  isExpired: boolean,
  edit: () => void,
  remove: () => void,
): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Number", value: formatCreditCardNumber(creditCard.last4Digits as string, creditCard.type) }],
    },
    {
      lineItems: [
        { name: "Holder name", value: creditCard.cardHolderName ?? "Unspecified", icon: faBuilding },
        {
          name: "Expiration date",
          value: `${creditCard.expirationMonth?.padStart(2, "0")}-${creditCard.expirationYear}`,
          icon: faCalendarCheck,
        },
      ],
    },
  ],
  isLoading: isUpdating,
  menu: [
    {
      label: "Edit billing address",
      icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
      command: edit,
    },
    { label: "Delete", icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />, command: remove },
  ],
  image: <CreditCardImage creditCard={creditCard} />,
  badge: isExpired ? { text: "expired", colorStyle: "red" } : undefined,
})

export { PaymentMethod }
